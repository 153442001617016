<template>
  <div class="page-content">
    <!-- Search Section -->
    <section id="search-section" class="page-section bg-white text-white mb-0">
      <div class="container align-items-center">

        <!-- Search Heading -->
        <div class="text-center mt-3 mb-3">
          <h1 class="page-section-heading text-uppercase text-secondary pb-lg-3">Zoeken</h1>
        </div>

        <!-- Search Section Content -->
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <p class="text-primary">Op de website worden standaard alle beschrijvingen (zo mogelijk voorzien van een
              afbeelding) van items uit de Brabant-Collectie getoond: boeken, tijdschrift(artikel)en, foto’s,
              prenten, kaarten, tekeningen, AV-materiaal etc.
            </p>
            <p class="text-primary">Verfijnen van deze grote set is mogelijk door één of meerdere facetten in de
              linkerkolom aan te vinken. Deze facetten kunnen alfabetisch (met de knop A-Z) en/of numeriek (met de
              knop 0-9) gesorteerd worden. De geactiveerde facetten verschijnen bovenaan de pagina. De selectie
              wordt ongedaan gemaakt door het facet uit te vinken.
            </p>
            <p class="text-primary">Door het invullen van één of meerdere termen in de zoekbalk kan specifiek
              gezocht worden in de collectie. Meerdere zoektermen worden standaard gecombineerd met de operator
              “EN”. Zoeken op bijvoorbeeld de termen kerk Waalre levert items op met in de beschrijving zowel het
              woord kerk als het woord Waalre. Door zoektermen tussen aanhalingstekens te zetten, wordt gezocht op
              een exacte woordcombinatie, bijvoorbeeld “Hertogen van Brabant”. Daarnaast kan in één zoekopdracht
              op verschillende woordcombinaties gezocht worden door het truncatie-symbool * te gebruiken. Zo
              levert zoeken op kerk* niet alleen resultaten op met kerk in de beschrijving, maar bijvoorbeeld ook
              met kerken, kerktoren, kerkbank etc.
            </p>
            <p class="text-primary">Door alleen een * als zoekterm in te vullen, worden alle resultaten getoond.
            </p>
            <p class="text-primary">Gebruik <span class="font-weight-bold font-italic">Uitgebreid zoeken</span>
              voor specifieke zoekacties (bijvoorbeeld zoeken op vervaardiger, titel, omschrijving etc.)
              of voor het combineren met andere operatoren (“NIET”, “OF”).
            </p>
            <ul class="text-primary">
              <li>Bij zoeken op “<span class="font-italic">EN</span>” wordt gezocht op resultaten die
                beide zoekopdrachten bevatten.
                Bijvoorbeeld: <span class="font-italic">kerk</span> EN <span class="font-italic">Waalre</span>
                geeft enkel die resultaten die zowel kerk als Waalre bevatten.
              </li>
              <li>Zoeken met “OF” geeft alle resultaten die een van beide zoekwoorden bevatten. Bijvoorbeeld:
                <span
                    class="font-italic">kerk
                </span> OF <span class="font-italic">Waalre</span> geeft alle resultaten die <span class="font-italic">kerk</span>
                of <span class="font-italic">Waalre</span> bevatten.
              </li>
              <li>Gebruik van de operator “NIET” geeft enkel resultaten die wel de eerste zoekopdracht, maar niet
                de
                tweede zoekopdracht bevatten. Bijvoorbeeld: <span class="font-italic">kerk</span> NIET <span
                    class="font-italic">Waalre</span> geeft alle resultaten die <span
                    class="font-italic">kerk</span>
                bevatten, maar niet het woord <span class="font-italic">Waalre</span>.
              </li>
            </ul>
            <p class="text-primary">Klikken op het “plusteken (+)” voegt een extra zoekveld toe.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Presentation Section -->
    <section id="presentation-section" class="page-section bg-white text-white">
      <div class="container align-items-center">

        <!-- Presentation Section Heading -->
        <div class="text-center mb-3 mt-3 pt-lg-5">
          <h1 class="page-section-heading text-uppercase text-secondary pb-lg-3">Presentatie</h1>
        </div>

        <!-- Presentation Section Content -->
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <p class="text-primary">De gevonden items kunnen op twee manieren worden getoond: als lijst of als
              gallery. Switchen tussen beide weergaves kan met de knoppen rechtsboven de gepresenteerde
              resultaten: <img src="../images/grid.png">
            </p>
            <p class="text-primary">Door te klikken op een zoekresultaat verschijnt de volledige beschrijving van
              het betreffende object.
            </p>
            <p class="text-primary">Met de knoppen <img src="../images/Volgende.png" height="30"> en <img
                src="../images/Vorige.png" height="30"> kan gebladerd worden binnen de gevonden resultaten.
            </p>
            <p class="text-primary">Tekstgedeeltes in <span class="text-secondary"><u>blauw</u></span> zijn
              clickable. Hierdoor wordt automatisch een nieuwe
              zoekactie gestart met desbetreffende zoekterm.
            </p>
            <p class="text-primary">De beschikbaarheid van boeken en seriële werken dient apart nagezocht te worden
              in de online catalogus van de Universiteitsbibliotheek Tilburg. Zie hiervoor:
              <a href="https://tilburguniversity.on.worldcat.org/discovery" target="_blank"
                 class="text-secondary">Catalogus
                Universiteitsbibliotheek</a>.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Help close button -->
    <CloseButton/>

  </div>
</template>

<script>
import CloseButton from '../components/CloseButton'

export default {
  name: 'Help',
  components: {
    CloseButton
  },
}
</script>